export function GenerateGuid() {
    if (self && self.crypto && typeof self.crypto.randomUUID === "function") {
        return crypto.randomUUID();
    } else if (self && self.crypto && typeof self.crypto.getRandomValues === "function") {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (parseInt(c, 10) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c, 10) / 4)))).toString(16)
        );
    } else {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            var r = (Math.random() * 16) | 0,
                v = c == "x" ? r : (r & 0x3) | 0x8;
            return v.toString(16);
        });
    }
}
