import { Box, Typography } from "@mui/material";

const NoLogin = () => {
    return (
        <Box
            sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                justifyContent: "center"
            }}
        >
            <Typography align="center" variant="h1" sx={{ fontSize: "h3.fontSize" }}>
                Please Login
            </Typography>
        </Box>
    );
};

export default NoLogin;
